import React from 'react'

// images
import LogoImg from '../../assets/icons/logo.svg'

const CustomModal = ({ onClose }) => {
    return (
        <div className='modal-root'>
            <div className='modal-wrapper' onClick={onClose}></div>
            <div className='modal-content'>
                <p className='modal-title'>Thank you for subscribing!</p>
                <p className='modal-subtitle'>
                    You have successfully subscribed to our list. Please check
                    your email to confirm your subscription.
                </p>
                <div className='modal-logo'>
                    <img src={LogoImg} alt='logo-img' />
                </div>
            </div>
        </div>
    )
}

export default CustomModal
