import { useEffect, useMemo, useRef, useState } from 'react'

const UseCountDownHook = () => {
    const [countdown, setCountdown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    })

    const hasInitialCall = useRef(false)

    let interval = useRef()

    const start_date = useMemo(() => new Date('2024-02-02'), [])

    useEffect(() => {
        const updateCountdown = () => {
            const now = new Date()
            const endDate = new Date(start_date)
            const remainingTime = endDate - now

            if (remainingTime <= 0) {
                clearInterval(interval.current)
            } else {
                const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24))
                const hours = Math.floor(
                    (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
                )
                const minutes = Math.floor(
                    (remainingTime % (1000 * 60 * 60)) / (1000 * 60),
                )
                const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000)

                setCountdown({ days, hours, minutes, seconds })
            }
        }

        interval.current = setInterval(updateCountdown, 1000)

        if (!hasInitialCall.current) {
            updateCountdown()
            hasInitialCall.current = true
        }

        return () => clearInterval(interval.current)
    }, [start_date])

    return countdown
}

export default UseCountDownHook
