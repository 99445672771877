import MailchimpSubscribe from 'react-mailchimp-subscribe'

// custom components
import ComingSoon from '../components/ComingSoon'

const SubscribeNewsletter = () => {
    const MAILCHIMP_URL = `https://yahoo.us17.list-manage.com/subscribe/post?u=4e3e975563d64676adf8f338f&amp;id=02434afa10&amp;f_id=00f568e0f0`

    return (
        <MailchimpSubscribe
            url={MAILCHIMP_URL}
            render={(props) => {
                const { subscribe, status, message } = props || {}
                return (
                    <ComingSoon
                        status={status}
                        message={message}
                        onValidated={(formData) => subscribe(formData)}
                    />
                )
            }}
        />
    )
}

export default SubscribeNewsletter
