import React from 'react'

// pages
import SubscribeNewsletter from './pages/SubscribeNewsletter'

const App = () => {
    return <SubscribeNewsletter />
}

export default App
