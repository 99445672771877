import React from 'react'

// custom hooks
import UseCountDown from '../../hooks/UseCountDown'

const CountDown = () => {
    const countdown = UseCountDown()

    return (
        <div className='timer-section'>
            <div className='timer-wrapper'>
                <span className='time-number'>{countdown.days}</span>
                <span className='time-label'>Days</span>
            </div>
            <div className='time-divider'>:</div>
            <div className='timer-wrapper'>
                <span className='time-number'>{countdown.hours}</span>
                <span className='time-label'>Hours</span>
            </div>
            <div className='time-divider'>:</div>
            <div className='timer-wrapper'>
                <span className='time-number'>{countdown.minutes}</span>
                <span className='time-label'>Minutes</span>
            </div>
            <div className='time-divider'>:</div>
            <div className='timer-wrapper'>
                <span className='time-number'>{countdown.seconds}</span>
                <span className='time-label'>Seconds</span>
            </div>
        </div>
    )
}

export default CountDown
